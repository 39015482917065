<template>
  <article class="event" @click="$router.push({name: 'EventDetails', params: { id: event.id }});">
    <div class="event-top">
      <div class="grid-12">
        <div class="col-sp-4 row-sp-12">
          <p class="promotion" v-if="event.promotion == 1">
            Gesponsert
          </p>
        </div>
        <div class="col-sp-8 row-sp-12 t-right">
          <div class="label button bgcolor-blue color-white">
            {{ event.categories }}
          </div>
        </div>
      </div>
    </div>
    <div class="eventBox">
      <img class="eventImage" :src="config.projectURL + 'assets/images/events/'+event.image+'.jpg'">
      <div class="imageCover" />
      <div class="eventWrapper">
        <div class="grid-12">
          <div class="col-sp-2 row-sp-12" style="align-self: center;">
            <div class="eventDate t-center">
              <h5>{{ getMonthFromDate(event.startTime).slice(0, 3) }}.</h5>
              <h6>{{ getDayFromDate(event.startTime) }}</h6>
            </div>
          </div>
          <div class="col-sp-10 row-sp-12" style="margin-left: 8px;">
            <h4>{{ event.title }}</h4>
            <h5>{{ event.subTitle }}</h5>
            <h5 v-if="event.distance">
              {{ event.cityName + ' - ' + Math.round(event.distance * 10) / 10 }} km
            </h5>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Event",
  props: ["event"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    getDayFromDate(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2];
    },
    getMonthFromDate(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      if (jsDate[1] == '01'){return 'Januar'}
      if (jsDate[1] == '02'){return 'Februar'}
      if (jsDate[1] == '03'){return 'März'}
      if (jsDate[1] == '04'){return 'April'}
      if (jsDate[1] == '05'){return 'Mai'}
      if (jsDate[1] == '06'){return 'Juni'}
      if (jsDate[1] == '07'){return 'Juli'}
      if (jsDate[1] == '08'){return 'August'}
      if (jsDate[1] == '09'){return 'September'}
      if (jsDate[1] == '10'){return 'Oktober'}
      if (jsDate[1] == '11'){return 'November'}
      if (jsDate[1] == '12'){return 'Dezember'}
    },
  }
}
</script>

<style scoped>
.event{
  position: relative;
  margin-bottom: 32px;
  cursor: pointer;
}
.event .event-top{
  margin-bottom: -16px;
  position: relative;
  z-index: 9;
  clear: both;
}
.event .event-top .label{
  padding: 10px 8px;
  text-transform: none;
  margin-right: 16px;
}
.event .event-top .promotion{
  font-size: 0.8em;
  color: rgba(255,255,255,0.6);
  font-family: 'Helvetica';
  padding-left: 4px;
}

.event .eventBox{
  background-color: #1E1E1E;
  border-radius: 10px;
}

.event .eventImage{
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
}
.event .eventWrapper{
  max-width: 100%;
  z-index: 4;
  padding: 8px 4px;
}
.event .eventWrapper .eventDate{
  background: #2068ff;
  padding: 24px 0;
  margin: 4px;
  border-radius: 10px;
}
.event .eventWrapper .eventDate h5{
  color: #ffffff !important;
  font-weight: 600;
  font-size: 1.1em;
  padding: unset !important;
}

.event .eventDate h6{
  color: #ffffff8a;
  padding-top: 4px;
  font-size: 18px;
}

.event .eventWrapper h4{
  font-size: 1.5em;
  font-family: 'Helvetica';
  font-weight: 600;
  color: white;
  margin-bottom: 0px;
  padding-bottom: 6px;
  margin-top: 10px;
}
.event .eventWrapper h5{
  font-size: 1em;
  font-family: 'Helvetica';
  font-weight: 300;
  color: white;
  padding-bottom: 6px;
}
</style>