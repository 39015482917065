<template>
  <div>
    <main className="content-500" id="settings">
      <h1 class="t-center">
        Einstellungen
      </h1>
      <section id="profilheader">
        <img :src="config.projectURL + 'assets/images/users/' + user.avatar" class="image width-150 circular">
        <div class="profilheaderInfos">
          <button class="button profileImageButton bgcolor-blue color-white br-8" @click="avatarModal = true">
            Profilbild bearbeiten
          </button>
        </div>
      </section>
      <section class="pad-16">
        <div class="bgcolor-second pad-16 br-10 t-left">

          <div class="grid-12">
            <div class="col-sp-12 row-sp-12 pad-4">
              <div class="field pad-4">
                <label>Vorname</label>
                <input type="text" name="firstname" placeholder="Vorname" v-model="firstname" @keydown="status = ''" maxlength="20" required>
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4">
              <div class="field pad-4">
                <label>Nachname</label>
                <input type="text" name="lastname" placeholder="Nachname" v-model="lastname" @keydown="status = ''" maxlength="20" required>
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4">
              <div class="field pad-4">
                <label>Geschlecht</label>
                <select required v-model="gender">
                  <option value="male" selected>Männlich</option>
                  <option value="female">Weiblich</option>
                  <option value="diverse">Divers</option>
                </select>
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4">
              <div class="field pad-4">
                <label>E-Mail</label>
                <input type="email" name="email" placeholder="E-Mail" v-model="email" @keydown="status = ''" required>
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4">
              <div class="field pad-4">
                <label>Telefonnnummer</label>
                <input type="tel" name="phone" placeholder="Telefonnummer" v-model="phone" @keydown="status = ''" required>
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4">
              <div class="field pad-4">
                <label>Passwort</label>
                <input type="password" name="password" autocomplete="off" placeholder="Leer lassen, wenn keine Änderung" v-model="pw1" @keydown="status = ''">
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4">
              <div class="field pad-4">
                <label>Passwort wiederholen</label>
                <input type="password" name="password2" autocomplete="off" placeholder="Leer lassen, wenn keine Änderung" v-model="pw2" @keydown="status = ''">
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4">
              <!--
              <p class="feedbackmessage t-center pad-8 color-red" v-if="firstname.length >= 21 || lastname.length >= 21">
                Dein Vor- oder Nachname sind zu lang.
              </p>

              <p class="feedbackmessage t-center pad-8 color-red" v-if="firstname.length == 0 || lastname.length == 0">
                Vor- und Nachname sind Pflichtfelder
              </p>
              <p class="feedbackmessage t-center pad-8 color-red" v-if="status === 'pw' || pw1.length > 0 && pw2.length > 0 && pw1 != pw2 || pw1.length < 8 && pw2.length > 0">
                Das Passwort ist nicht identisch oder kürzer als 8 Zeichen.
              </p>
              -->
              <p class="feedbackmessage t-center pad-8 color-red" v-if="status === 'error'">
                Es ist ein Fehler aufgetreten, bitte versuche es erneut.
              </p>
              <p class="feedbackmessage t-center pad-8 color-green" v-if="status === 'ok'">
                Die Änderungen wurden erfolgreich übernommen.
              </p>

            </div>
            <div class="col-sp-12 row-sp-12 t-center pad-4">
              <div class="pad-8" />
              <button class="button bgcolor-blue fluid color-white br-24" @click="edit">
                Änderungen übernehmen
              </button>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4 t-center">
              <div class="pad-16" />
              <div @click="deleteModal = true" class="color-blue" style="text-decoration: underline; cursor: pointer;">
                Account löschen
              </div>
              <div class="pad-16" />
            </div>
          </div>
        </div>
      </section>
    </main>
    <div class="vueModal" id="deleteModal" v-if="deleteModal" style="position: fixed;">
      <div class="vueModalOuter" @click="deleteModal = false" />
      <div class="vueModalInner bgcolor-second">
        <div class="pad-16" />
        <div class="t-center pad-8">
          <h3>Möchtest du deinen Account wirklich löschen?</h3>
        </div>
        <div class="pad-16">
          <div class="grid-12">
            <div class="col-sp-12 row-sp-12  pad-4">
              <button @click="deleteModal = false" class="button fluid bgcolor-black circular color-white">
                Abbrechen
              </button>
            </div>
            <div class="col-sp-12 row-sp-12 pad-4 t-right">
              <button type="button" @click="deleteAccount" class="button fluid bgcolor-blue circular color-white">
                Account löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VueModal :show="avatarModal" @close="avatarModal = false" :approve="avatarBlob ? (loadingAvatar? 'lädt...' : 'speichern') : false" :approve-disabled="loadingAvatar" @approve="saveAvatar">
      <cropper-upload :init="true" :ratio="1" @cropped="c => avatarBlob = c" />
    </VueModal>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import CropperUpload from "@/components/images/cropperUpload";

export default {
  name: "Settings",
  title: "Settings",
  components: {
    CropperUpload
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      email: this.user.email,
      phone: this.user.phone,
      gender: this.user.gender,
      pw1: '',
      pw2: '',
      deleteModal: false,
      status: '',
      avatarModal: false,
      avatarBlob: false,
      loadingAvatar: false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    saveAvatar(){
      this.loadingAvatar = true
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('avatar', this.avatarBlob)
      this.axios.post(this.config.projectURL + 'settings/changeAvatar', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            if(response.status === 250){
              _this.user.avatar = response.data.avatar
              _this.avatarModal = false;
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            _this.loadingAvatar = false
          })
    },
    edit(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('firstname', this.firstname)
      fD.append('lastname', this.lastname)
      fD.append('gender', this.gender)
      fD.append('email', this.email)
      fD.append('phone', this.phone)
      fD.append('password', this.pw1)
      fD.append('password2', this.pw2)
      this.axios.post(this.config.projectURL + 'settings/edit', fD)
          .then(response => {
            if(response.status === 250){
              this.status = 'ok'
              const data = response.data
              this.user.email = data.email
              this.user.firstname = data.firstname
              this.user.lastname = data.lastname
              this.user.gender = data.gender
              this.user.phone = data.phone
            }else if(response.status === 251){
              this.status = 'pw'
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    deleteAccount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'settings/deleteAccount', fD)
          .then(response => {
            if(response.status === 250) this.auth.logout()
          })
    },
  }
}
</script>

<style scoped>
main#settings{
  padding-bottom: 80px;
  padding-top: 0!important;
}
main#settings h1{
  margin-top: 64px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
#profilheader{
  text-align: center;
  padding-top: 40px;
}
#profilheader img{
  border: 2px solid #ffffff;
  position: relative;
  z-index: 1;
}
#profilheader .profilheaderInfos{
  background-color: var(--second-bg-color);
  width: calc(100% - 32px);
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 100px;
  position: relative;
  z-index: 0;
  margin-top: -80px;
  padding-bottom: 16px;
  margin-bottom: 32px;
}
#profilheader .profilheaderInfos button.profileImageButton{
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
}
#settings section .field label{
  display: block;
}
#settings section .field input,
#settings section .field select{
  display: block;
  width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.25);
  color: rgba(255,255,255,0.8);
  padding: 12px;
  margin-bottom: 8px;
  box-sizing: border-box;
}
#deleteModal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: #00000095;
}
.vueModalInner{
  width: calc(100% - 32px);
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 16px;
}
</style>